import papers from '../images/papers.png';
const orderMaterialFeauture = svs.core.detect.feature('ft-order-material');
const orderMaterial = orderMaterialFeauture ? {
  '/bestall-material': {
    label: 'Beställ material',
    header: {
      backgroundImage: papers
    }
  }
} : {};
const myOrders = orderMaterialFeauture ? {
  '/mina-bestallningar': {
    label: 'Mina beställningar',
    header: {
      backgroundImage: papers
    }
  }
} : {};
const metadata = {
  title: 'Material',
  routes: {
    '/oversikt': {
      label: 'Översikt',
      header: {
        backgroundImage: papers
      }
    },
    ...orderMaterial,
    ...myOrders,
    '/skyltmaterial': {
      label: 'Skyltmaterial',
      header: {
        backgroundImage: papers
      }
    },
    '/tilldelning': {
      label: 'Tilldelning',
      header: {
        backgroundImage: papers
      }
    }
  }
};
export { metadata };