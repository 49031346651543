import { getBrandColor } from '@common/brand-colors/utils';
import { getClassNamesFromArray, getContrastColor, getBgImgValue, withCurrentCDN } from '@common/utils';
import './styles.less';
import { ThemeContext } from '@common/components/theme-provider';
import { BRANDINGS } from '@common/constants';
const {
  useEffect,
  useState,
  useContext
} = React;
const {
  Link
} = ReactRouterDOM;
const {
  ReactBrand: Brand
} = svs.ui;
const Symbols = svs.ui.ReactSymbols;
const {
  useLocation
} = ReactRouterDOM;
export const PageHeader = ({
  backgroundImageSrc,
  branding,
  breadcrumb,
  breadcrumbLink,
  hasBreadcrumb,
  isLoading,
  title,
  hideOnPrint,
  logo
}) => {
  const [element, setElement] = useState(false);
  const elemSelector = '.page-header-container';
  const theme = useContext(ThemeContext);
  let image = backgroundImageSrc ?? theme?.header?.backgroundImage;
  if (backgroundImageSrc) {
    image = withCurrentCDN(image);
  }
  let logoImage = logo ?? theme?.header?.logo;
  if (logo) {
    logoImage = withCurrentCDN(logoImage);
  }
  const location = useLocation();
  const locationClassName = location?.pathname?.replace(/^\/|\/$/g, '');
  useEffect(() => {
    const htmlElement = document.querySelector(elemSelector);
    if (htmlElement) {
      setElement(htmlElement);
    }
  }, []);
  if (!element) {
    return null;
  }
  const brandColor = branding ? getBrandColor(branding) : {};
  const backgroundImage = branding ? getBgImgValue(image, brandColor.SECONDARY) : undefined;
  return ReactDOM.createPortal(<div className={getClassNamesFromArray(['page-header-background', locationClassName, image && 'has-image', backgroundImageSrc && 'cover'])} style={branding ? {
    backgroundImage
  } : {
    backgroundImage: `url("${image}")`
  }}>
      <div className={getClassNamesFromArray(['page-header ompo-main-content page-header-container', hideOnPrint && 'hide-on-print'])}>
        {logoImage && <div className="logo-container">
            <img src={logoImage} />
          </div>}
        <div className="page-header-title-container">
          <p>
            {hasBreadcrumb !== false ? <Link style={branding ? {
            color: getContrastColor(brandColor.SECONDARY)
          } : {}} to={breadcrumbLink}>
                  {breadcrumb}
                </Link> : <span>&nbsp;</span>}

          </p>
          <div className="header-container">
            <h1 className="h0 has-skeleton" style={branding ? {
            color: getContrastColor(brandColor.SECONDARY)
          } : {}}>
              {!isLoading && title}
              {isLoading && '...'}
            </h1>
          </div>
        </div>
      </div>
      {branding && <div style={{
      backgroundColor: brandColor.PRIMARY
    }}>
          <div className="ompo-main-content u-flex">
            {branding === 'pix-mixen' ? <span className="brand brand-200 brand brand-default">
                  <Brand emblem />
                  <Symbols height={56} name="pix-mixen" />
                </span> : <Brand brand={branding} emblem={branding !== BRANDINGS.VINNARE} size={200} />}
          </div>
        </div>}
    </div>, document.querySelector(elemSelector));
};
PageHeader.propTypes = {
  backgroundImageSrc: PropTypes.string,
  branding: PropTypes.string,
  breadcrumb: PropTypes.string,
  breadcrumbLink: PropTypes.string,
  hasBreadcrumb: PropTypes.bool,
  hideOnPrint: PropTypes.bool,
  isLoading: PropTypes.bool,
  logo: PropTypes.string,
  title: PropTypes.string
};