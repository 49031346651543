const {
  createApi,
  fetchBaseQuery
} = RTKQ;
const {
  retailerId
} = svs.core.session.data.userSession;
const {
  fetch,
  url: baseUrl
} = svs.core.jupiterFetch;
const logger = svs.core.log.getLogger('common/retailer-api');
const MAX_DELAY = 1000;
const MIN_DELAY = 300;
const sortRetUsers = (retUsers, key) => {
  retUsers.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
  return retUsers;
};
const getUserRole = ({
  retailer,
  retailerAdmin,
  retailerLogin
} = {}) => {
  if (retailerAdmin) {
    return 'ADMIN_RETAILER';
  }
  if (retailer) {
    return 'RETAILER_SALES';
  }
  if (retailerLogin) {
    return 'RETAILER_LOGIN';
  }
  return '';
};
export const retailerApi = createApi({
  reducerPath: 'retailerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl(),
    credentials: 'include',
    fetchFn: fetch.fetch
  }),
  tagTypes: ['Users'],
  endpoints: builder => ({
    getUsers: builder.query({
      query: () => `/retailer/2/users`,
      transformResponse: response => ({
        retUsers: sortRetUsers(response.retUsers, 'alias'),
        moreToRequest: response.moreToRequest
      }),
      providesTags: [{
        type: 'Users',
        id: 'LIST'
      }]
    }),
    addUser: builder.mutation({
      query: data => {
        const body = {
          ...data,
          retailerId
        };
        return {
          url: `/retailer/2/users`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: [{
        type: 'Users',
        id: 'LIST'
      }]
    }),
    getUser: builder.query({
      query: userId => `/retailer/2/${userId}/userinfo`,
      transformResponse: response => ({
        ...response,
        userAlias: response.alias,
        mobileNum: response.mobile
      }),
      providesTags: (_result, _error, userId) => [{
        type: 'Users',
        id: userId
      }]
    }),
    updatePassword: builder.mutation({
      query: body => ({
        url: `/user/2/password/`,
        method: 'PUT',
        body
      })
    }),
    setTmpPassword: builder.mutation({
      query: body => ({
        url: `/user/2/password/`,
        method: 'POST',
        body
      })
    }),
    updateUser: builder.mutation({
      query: ({
        userId,
        ...data
      }) => {
        const body = {
          ...data,
          retailerId
        };
        return {
          url: `/retailer/4/users/${userId}`,
          method: 'PUT',
          body
        };
      },
      transformResponse: response => ({
        ...response,
        isSuccess: true
      }),
      async onQueryStarted({
        userId
      }, {
        dispatch,
        queryFulfilled
      }) {
        try {
          const {
            data
          } = await queryFulfilled;
          if (data) {
            setTimeout(() => {
              dispatch(retailerApi.util.invalidateTags([{
                type: 'Users',
                id: userId
              }]));
            }, Math.round(Math.random() * (MAX_DELAY - MIN_DELAY + 1) + MIN_DELAY));
          }
        } catch (err) {
          const apiErrorCode = err?.error?.data?.error?.code;
          logger.error(`Error while updating user info: ${apiErrorCode}`);
        }
      },
      invalidatesTags: [{
        type: 'Users',
        id: 'LIST'
      }]
    }),
    deleteUser: builder.mutation({
      query: userId => {
        return {
          url: `/retailer/2/users/${userId}?retailerId=${retailerId}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: [{
        type: 'Users',
        id: 'LIST'
      }]
    }),
    updateUserMobile: builder.mutation({
      query: ({
        userId,
        ...body
      }) => ({
        url: `/retailer/2/${userId}/mobile`,
        method: 'PUT',
        body
      }),
      transformResponse: response => ({
        ...response,
        isSuccess: true
      }),
      async onQueryStarted({
        userId
      }, {
        dispatch,
        queryFulfilled
      }) {
        try {
          const {
            data
          } = await queryFulfilled;
          if (data) {
            setTimeout(() => {
              dispatch(retailerApi.util.invalidateTags([{
                type: 'Users',
                id: userId
              }]));
            }, Math.round(Math.random() * (MAX_DELAY - MIN_DELAY + 1) + MIN_DELAY));
          }
        } catch (err) {
          const apiErrorCode = err?.error?.data?.error?.code;
          logger.error(`Error while updating user Mobile: ${apiErrorCode}`);
        }
      },
      invalidatesTags: [{
        type: 'Users',
        id: 'LIST'
      }]
    }),
    updateUserPassword: builder.mutation({
      query: body => ({
        url: `/user/2/password`,
        method: 'PUT',
        body
      }),
      async onQueryStarted({
        userId
      }, {
        dispatch,
        queryFulfilled
      }) {
        try {
          const {
            data
          } = await queryFulfilled;
          if (data) {
            setTimeout(() => {
              dispatch(retailerApi.util.invalidateTags([{
                type: 'Users',
                id: userId
              }]));
            }, Math.round(Math.random() * (MAX_DELAY - MIN_DELAY + 1) + MIN_DELAY));
          }
        } catch (err) {
          const apiErrorCode = err?.error?.data?.error?.code;
          logger.error(`Error while updating user password: ${apiErrorCode}`);
        }
      },
      invalidatesTags: [{
        type: 'Users',
        id: 'LIST'
      }]
    }),
    updateUserPrivileges: builder.mutation({
      query: body => ({
        url: `/user/3/privileges`,
        method: 'PUT',
        body
      }),
      transformResponse: response => ({
        ...response,
        isSuccess: true
      }),
      async onQueryStarted({
        userId
      }, {
        dispatch,
        queryFulfilled
      }) {
        try {
          const {
            data
          } = await queryFulfilled;
          if (data) {
            setTimeout(() => {
              dispatch(retailerApi.util.invalidateTags([{
                type: 'Users',
                id: userId
              }]));
            }, Math.round(Math.random() * (MAX_DELAY - MIN_DELAY + 1) + MIN_DELAY));
          }
        } catch (err) {
          const apiErrorCode = err?.error?.data?.error?.code;
          logger.error(`Error while updating user privileges: ${apiErrorCode}`);
        }
      },
      invalidatesTags: [{
        type: 'Users',
        id: 'LIST'
      }]
    }),
    getUserProfile: builder.query({
      transformResponse: async ({
        profile: {
          retailer: {
            basicCategory,
            contacts,
            retailerId,
            sellType,
            name,
            ...retailer
          } = {},
          ...profile
        }
      }) => {
        return {
          ...profile,
          user: {
            ...profile?.user,
            role: getUserRole(profile?.user?.properties)
          },
          retailer: {
            ...retailer,
            basicCategory: basicCategory.text,
            id: retailerId,
            name: name[0],
            gameManagers: (contacts || []).filter(c => c.type === 'Spelansvarig'),
            sellType: sellType.text,
            sellTypeId: sellType.value
          }
        };
      },
      query: () => '/retailer/1/userprofile'
    }),
    getMaterialAllocation: builder.query({
      transformResponse: ({
        retailerArticleAllocations
      }) => retailerArticleAllocations,
      query: () => '/retailer/1/advertising/articleallocations'
    })
  })
});