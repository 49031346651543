import { createContext } from 'react';
import { getMetadataForPath } from '@common/components/router/utils';
import { withCurrentCDN } from '@common/utils';
import defaultBackground from './images/hero-icons-desktop.png';
const {
  withRouter
} = ReactRouterDOM;
export const ThemeContext = createContext();
const ThemeProvider = ({
  children,
  moduleName,
  headerBackgroundImage,
  ...props
}) => {
  if (moduleName === null || moduleName === undefined) {
    throw Error('ThemeProvider: Module name is required configuration.');
  }
  const {
    routes
  } = getMetadataForPath() || {};
  const regex = /(\/[a-z1-9-_]*)\/?.*/;
  const findLocationPath = props.location?.pathname.includes(routes) ? props.location?.pathname : props.location?.pathname.match(regex)[1];
  const pathConfig = routes && props.location?.pathname !== '/' ? routes[findLocationPath] : {};
  const pathHeaderBackgroundImage = pathConfig?.header?.backgroundImage;
  const backgroundImage = pathHeaderBackgroundImage ?? headerBackgroundImage ?? defaultBackground;
  const logo = pathConfig?.header?.logo;
  const theme = {
    header: {
      backgroundImage: withCurrentCDN(backgroundImage).replace(/(.*\/images\/)(\S+)(\/.+)/, `$1${moduleName}$3`),
      logo: logo ? withCurrentCDN(logo).replace(/(.*\/images\/)(\S+)(\/.+)/, `$1${moduleName}$3`) : undefined
    }
  };
  return <ThemeContext.Provider value={theme}>
      {children}
    </ThemeContext.Provider>;
};
export default withRouter(ThemeProvider);
export const noRouterThemeProvider = ThemeProvider;