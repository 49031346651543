import { isUserAdmin } from '@common/utils';
import { selectIsSpelombud } from '@common/selectors';
const tipsSmFeauture = svs.core.detect.feature('ft-tips-sm');
const tipsSm = tipsSmFeauture ? {
  '/tips-sm': {
    label: 'Tips-SM',
    navBar: {
      style: {
        color: '#fff',
        backgroundColor: '#142E4F'
      },
      activeStyle: {
        color: 'white',
        'border-bottom-color': '#fff'
      }
    },
    useIsAllowed: () => {
      return ReactRedux.useSelector(selectIsSpelombud);
    }
  }
} : {};
const metadata = {
  title: 'Min butik',
  routes: {
    '/oversikt': {
      label: 'Översikt'
    },
    '/material': {
      label: 'Material'
    },
    '/tavlingar': {
      label: 'Tävlingar'
    },
    '/kampanjer': {
      label: 'Kampanjer'
    },
    '/mitt-ombud': {
      label: 'Mitt ombud'
    },
    '/mitt-team': {
      label: 'Mitt team',
      useIsAllowed: isUserAdmin
    },
    ...tipsSm
  }
};
export { metadata };